interface RemoveIconProps {
  className?: string;
  onClick?: () => unknown;
}

export function RemoveIcon({ className, onClick }: RemoveIconProps) {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 1.25C4.0625 1.25 1.25 4.0625 1.25 7.5C1.25 10.9375 4.0625 13.75 7.5 13.75C10.9375 13.75 13.75 10.9375 13.75 7.5C13.75 4.0625 10.9375 1.25 7.5 1.25ZM10.3125 9.6875C10.5 9.875 10.5 10.1875 10.3125 10.375C10.25 10.4375 10.125 10.5 10 10.5C9.875 10.5 9.75 10.4375 9.6875 10.375L7.5 8.1875L5.3125 10.375C5.25 10.4375 5.125 10.5 5 10.5C4.875 10.5 4.75 10.4375 4.6875 10.375C4.5 10.1875 4.5 9.875 4.6875 9.6875L6.875 7.5L4.6875 5.3125C4.5 5.125 4.5 4.8125 4.6875 4.625C4.875 4.4375 5.1875 4.4375 5.375 4.625L7.5625 6.8125L9.75 4.625C9.9375 4.4375 10.25 4.4375 10.4375 4.625C10.625 4.8125 10.625 5.125 10.4375 5.3125L8.1875 7.5L10.3125 9.6875Z"
        fill="#C3C3C3"
      />
    </svg>
  );
}
