import React from 'react';
import TextareaAutoSize from 'react-textarea-autosize';

interface AutoResizeTextAreaProps {
  className?: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  onFocus: () => unknown;
  onBlur: () => unknown;
  isDisabled: boolean;
  placeholder?: string;
}

const AutoResizeTextArea = React.forwardRef<
  HTMLTextAreaElement,
  AutoResizeTextAreaProps
>(
  (
    { className, value, onChange, onFocus, onBlur, isDisabled, placeholder },
    ref
  ) => {
    return (
      <>
        <TextareaAutoSize
          ref={ref}
          cacheMeasurements
          className={className}
          placeholder={placeholder}
          maxRows={5}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={isDisabled}
        />
      </>
    );
  }
);
AutoResizeTextArea.displayName = 'AutoResizeTextArea';
export default AutoResizeTextArea;
