import { css } from '@emotion/react';

import { BackIcon } from '../assets/icons';

import styled from '@emotion/styled';
import { displaySmall } from '../styles';

import { useLocation } from '@reach/router';
import { goPreviousPage } from '../utils/route';
import { FeedType } from '../models/feedType';

interface DetailNavigationBarProps {
  feedType: FeedType;
}

export default function DetailNavigationBar({
  feedType,
}: DetailNavigationBarProps) {
  const location = useLocation();

  return (
    <nav css={navigationBarStyle}>
      <div className="navigation-container">
        <IconButton
          onClick={() => {
            goPreviousPage(location);
          }}
          aria-label="back button"
          // css={css`
          //   margin-left: -1.25rem;
          // `}
        >
          <BackIcon />
        </IconButton>

        <div
          css={css`
            display: flex;
            flex-direction: column;
            flex: 1 0;
            margin-right: var(--xlarge2);
          `}
        >
          <header
            css={[
              displaySmall,
              css`
                align-self: center;
              `,
            ]}
          >
            {feedType === FeedType.CREATOR && '피드'}
            {feedType === FeedType.USER && '커뮤니티'} 게시물 상세
          </header>
        </div>
      </div>
    </nav>
  );
}

const IconButton = styled.button`
  > svg {
    width: var(--xlarge2);
    height: var(--xlarge2);
  }
`;

const navigationBarStyle = css`
  flex: 0 0 auto;
  background: white;
  z-index: 10;

  display: block;
  padding-top: env(safe-area-inset-top);
  border-bottom: 1px solid #ededed;

  .navigation-container {
    display: flex;
    padding: 1.125rem var(--large);
  }
`;
